import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import { mapEdgesToNodes } from '../lib/helpers';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PortableText from '../components/portableText';
import ContactForm from '../components/Contact/ContactForm';
import PageFooter from '../components/PageFooter';

const ImprintPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const { _rawBody: body } = data.imprint;
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.seoImg} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>
      <Container style={{ marginTop: '4.875rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <PortableText blocks={body} />
        </div>
      </Container>

      <PageFooter></PageFooter>
    </Layout>
  );
};

export const query = graphql`
  query ImprintPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-7f947bcd-0218-5290-873b-6a5e30cdae0b" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              seoImg: fluid {
                src
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    imprint: sanityImprint {
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

export default localize(ImprintPage);
